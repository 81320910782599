











import {
  Vue, Component, Inject, ProvideReactive,
} from 'vue-property-decorator';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import { Polygon } from '@/models/entities/Polygon';
import PageHeader1 from '@/components/PageHeader1.vue';
import PolygonHomeView from '@/views/polygons/PolygonHomeView.vue';
import { ApiFacade } from '@/services/ApiFacade';

@Component({
  components: {
    PageHeader1,
    PolygonHomeView,
  },
})
export default class PolygonView extends Vue {
  @Inject() readonly appStore!: Context<AppModule>;
  @Inject() readonly apiFacade!: ApiFacade;
  @ProvideReactive() polygon: Polygon | null = null;
}
